import React, { useState } from "react";
import eye from "./images/eye.png";
import ribbon from "./images/ribbon.png";
import drop from "./images/drop.png";
import lips from "./images/lips.png";
import gender from "./images/gender.png";
import tooth from "./images/tooth.png";
import SliderArrows from "../SliderArrows/SliderArrows";
import * as styles from "./treatmentAreasSection.module.scss";
import Carousel from "@brainhubeu/react-carousel";
import { useTranslation } from "react-i18next";

const TreatmentAreasSection = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const treatmentSliderData = [
    {
      imgSrc: eye,
      translation: t("fertility.treatment.1"),
      extraStyle: { backgroundColor: "#ff6d74" },
    },
    {
      imgSrc: ribbon,
      translation: t("fertility.treatment.2"),
      extraStyle: { backgroundColor: "#62c2b5" },
    },
    {
      imgSrc: drop,
      translation: t("fertility.treatment.3"),
      extraStyle: { backgroundColor: "#8665e3" },
    },
    {
      imgSrc: lips,
      translation: t("fertility.treatment.4"),
      extraStyle: { backgroundColor: "#ff6d74" },
    },
    {
      imgSrc: gender,
      translation: t("fertility.treatment.5"),
      extraStyle: { backgroundColor: "#62c2b5" },
    },
    {
      imgSrc: tooth,
      translation: t("fertility.treatment.6"),
      extraStyle: { backgroundColor: "#8665e3" },
    },
  ];
  const renderAreas = treatmentSliderData.map(
    ({ imgSrc, translation, extraStyle }, index) => (
      <>
        <div key={index} className={styles.treatmentArea}>
          <img src={imgSrc} alt="" className={styles.image} />
          <p style={{ ...extraStyle }}>{translation}</p>
        </div>
        {index === treatmentSliderData.length - 1 && (
          <div className={styles.treatmentAreaMore}>
            <p className={styles.manyMore}>{t("fertility.more.title")}</p>
          </div>
        )}
      </>
    )
  );
  return (
    <>
      <div className={styles.treatmentSlider}>{renderAreas}</div>
      <div className={styles.treatmentSliderMobile}>
        <Carousel
          value={activeIndex}
          onChange={setActiveIndex}
          arrows={false}
          slidesPerPage={4.2}
          infinite
          breakpoints={{
            768: {
              slidesPerPage: 4.2,
            },
            600: {
              slidesPerPage: 2.3,
            },
          }}
        >
          {renderAreas}
        </Carousel>
        <div className={styles.sliderControls}>
          <SliderArrows
            goNext={() => setActiveIndex((i) => i + 1)}
            goPrev={() => setActiveIndex((i) => i - 1)}
          />
        </div>
        <p className={styles.manyMore}>{t("fertility.more.title")}</p>
      </div>
      <div className={styles.descriptionWrapper}>
        <p className={styles.description}>{t("fertility.more.copy")}</p>
      </div>
    </>
  );
};

export default TreatmentAreasSection;
