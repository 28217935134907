import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PageHeader from "../components/PageHeader/pageHeader";
import TreatmentAreasSection from "../components/TreatmentAreasSection/treatmentAreasSection";
import { useTranslation } from "react-i18next";

const NotFertilityClinic = () => {
  const { t } = useTranslation();
  return (
    <Layout menuColor={"black"}>
      <SEO title={t("menu.notAFertilityClinic")} />
      <PageHeader
        title={t("fertility.header")}
        desc={t("fertility.subheader")}
      />
      <TreatmentAreasSection />
    </Layout>
  );
};

export default NotFertilityClinic;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
